export enum EFontScale {
  F7 = "F7",
  F6 = "F6",
  F5 = "F5",
  F4 = "F4",
  F3 = "F3",
  F2 = "F2",
  F1 = "F1",
}

export enum ETypo {
  MAJOR = "MAJOR",
  SECTION_HEADER = "SECTION_HEADER",
  BLOCKQUOTE = "BLOCKQUOTE",
  BODY = "BODY",
  BIG_BODY = "BIG_BODY",
  META = "META",
  CAPTION = "CAPTION",
  FORM_TITLE = "FORM_TITLE",
  FORM_LABEL = "FORM_LABEL",
  FORM_INPUT = "FORM_INPUT",
}

export const FONT_FAMILY = {
  HEADING: "Styrene A, sans-serif",
  BODY: "Inter, sans-serif",
}

export const TYPO = {
  [EFontScale.F7]: {
    SIZE: "56px",
    LINE_HEIGHT: 1.2,
    WEIGHT: 500,
    FAMILY: FONT_FAMILY.HEADING,
  },
  [EFontScale.F5]: {
    SIZE: "40px",
    LINE_HEIGHT: 1.2,
    WEIGHT: 500,
    FAMILY: FONT_FAMILY.HEADING,
  },
  [EFontScale.F4]: {
    SIZE: "32px",
    LINE_HEIGHT: 1.4,
    WEIGHT: 500,
    FAMILY: FONT_FAMILY.HEADING,
  },
  [EFontScale.F3]: {
    SIZE: "24px",
    LINE_HEIGHT: 1.2,
    WEIGHT: 500,
    FAMILY: FONT_FAMILY.HEADING,
  },
  [EFontScale.F2]: {
    SIZE: "20px",
    LINE_HEIGHT: 1.8,
    WEIGHT: 400,
    FAMILY: FONT_FAMILY.BODY,
  },
  [EFontScale.F1]: {
    SIZE: "16px",
    LINE_HEIGHT: 1.8,
    WEIGHT: 400,
    FAMILY: FONT_FAMILY.BODY,
  },
}
