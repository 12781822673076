import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import { FONT_FAMILY, SPACE, COLOR, BREAKPOINT } from "../../design"

type MenuProps = {
  isCollapsed: boolean
}

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${SPACE.SPACE80};
  margin-bottom: ${SPACE.SPACE168};

  #menu-icon {
    display: none;
  }

  @media (max-width: ${BREAKPOINT.LARGE}) {
    margin-top: ${SPACE.SPACE48};
    margin-bottom: ${SPACE.SPACE80};
  }

  @media (max-width: calc(${BREAKPOINT.SMALL} + 100px)) {
    #menu-icon {
      display: flex;
      margin-left: auto;
    }

    #menu {
      display: ${(props: MenuProps) => (props.isCollapsed ? "none" : "")};
    }
  }
`

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-family: ${FONT_FAMILY.HEADING};
  z-index: 10;
  color: ${(props: MenuProps) => (props.isCollapsed ? "inherit" : COLOR.YELLOW500)};
`

export const Menu = styled.div`
  margin-left: auto;
  display: flex;

  a + a {
    margin-left: ${SPACE.SPACE64};
  }

  ${(props: MenuProps) => {
    if (!props.isCollapsed)
      return css`
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
        height: 100vh;
        width: 100vw;
        background-color: ${COLOR.BLUE100};

        flex-direction: column;
        justify-content: center;
      `
  }}
`

const MenuItem = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
`

export const NavBarItem = styled(MenuItem)`
  color: ${COLOR.BLUE100};
  font-size: 16px;
  font-weight: bold;
  font-family: ${FONT_FAMILY.BODY};

  &:hover {
    color: ${COLOR.BLUE300};
  }

  &:focus {
    color: ${COLOR.BLUE100};

    &::after {
      position: absolute;
      content: "";
      top: 100%;
      left: 0;
      right: 0;
      border-bottom: 2px solid;
    }
  }
`

export const HamburgerMenuItem = styled(MenuItem)`
  color: ${COLOR.YELLOW500};
  font-size: 24px;
  font-family: ${FONT_FAMILY.HEADING};
  padding: ${SPACE.SPACE24} 0 ${SPACE.SPACE24} 0;
  margin-left: ${SPACE.SPACE24};
  margin-right: ${SPACE.SPACE24};

  &:not(:last-child) {
    border-bottom: solid rgba(255, 200, 76, 0.2) 1px;
  }

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: ${COLOR.YELLOW900};
  }

  & + & {
    margin-left: ${SPACE.SPACE24};
  }
`
