import React from "react"
import { Global, css } from "@emotion/core"
import { Helmet } from "react-helmet"
import { ETypo, getTypo, COLOR } from "../design"
import StyreneAMediumWoff from "../assets/StyreneA-Medium.woff"
import StyreneAMediumWoff2 from "../assets/StyreneA-Medium.woff2"
import InterRegularwoff from "../assets/Inter-Regular.woff"
import InterRegularwoff2 from "../assets/Inter-Regular.woff2"
import InterMediumwoff from "../assets/Inter-Medium.woff"
import InterMediumwoff2 from "../assets/Inter-Medium.woff2"
import InterBoldwoff from "../assets/Inter-Bold.woff"
import InterBoldwoff2 from "../assets/Inter-Bold.woff2"

export default ({ children }) => (
  <div>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>UX House</title>
      <meta
        name="description"
        content="A community of UX enthusiasts and professionals targetted towards human-centered thinkers to
          collaborate, critique, and share their ideas."
      />
    </Helmet>
    <Global
      styles={css`
        * {
          box-sizing: border-box;
        }

        @font-face {
          font-family: "Styrene A";
          font-weight: 500;
          src: url(${StyreneAMediumWoff2}) format("woff2"), url(${StyreneAMediumWoff}) format("woff");
        }

        @font-face {
          font-family: "Inter";
          font-weight: 400;
          src: url(${InterRegularwoff2}) format("woff2"), url(${InterRegularwoff}) format("woff");
        }

        @font-face {
          font-family: "Inter";
          font-weight: 500;
          src: url(${InterMediumwoff2}) format("woff2"), url(${InterMediumwoff}) format("woff");
        }

        @font-face {
          font-family: "Inter";
          font-weight: 700;
          src: url(${InterBoldwoff2}) format("woff2"), url(${InterBoldwoff}) format("woff");
        }

        body {
          ${getTypo(ETypo.BODY)};
          background: radial-gradient(117.14% 184.43% at 6.74% -0.83%, #ffed7a 0%, #ffdfa2 100%);
          color: ${COLOR.BLUE100};
          margin: 0;
          padding: 0.5rem;
          min-height: 100vh;
          /* Calculate viewport width that excludes scrollbar width, see https://stackoverflow.com/questions/33606565/is-it-possible-to-calculate-the-viewport-width-vw-without-scrollbar/34884924 */
          width: calc(100vw - (100vw - 100%));
        }
      `}
    />
    {children}
  </div>
)
