import { ETypo, EFontScale, TYPO } from "../tokens/typo"
import { css, SerializedStyles } from "@emotion/core"
import { CSSProperties } from "react"

function typoMapper(typo: ETypo): EFontScale {
  switch (typo) {
    case ETypo.MAJOR:
      return EFontScale.F7
    case ETypo.SECTION_HEADER:
      return EFontScale.F5
    case ETypo.BLOCKQUOTE:
    case ETypo.BIG_BODY:
      return EFontScale.F4
    case ETypo.FORM_TITLE:
      return EFontScale.F3
    case ETypo.BODY:
    case ETypo.FORM_LABEL:
      return EFontScale.F2
    case ETypo.CAPTION:
    case ETypo.FORM_INPUT:
    case ETypo.META:
      return EFontScale.F1
  }
}

function getStyles(fontScale: EFontScale): SerializedStyles {
  const styles = TYPO[fontScale]

  return css`
    font-size: ${styles.SIZE};
    line-height: ${styles.LINE_HEIGHT};
    font-weight: ${styles.WEIGHT};
    font-family: ${styles.FAMILY};
  `
}

function getRawStyles(fontScale: EFontScale): CSSProperties {
  const styles = TYPO[fontScale]

  return {
    fontSize: styles.SIZE,
    lineHeight: styles.LINE_HEIGHT,
    fontWeight: styles.WEIGHT,
    fontFamily: styles.FAMILY,
  }
}

export function getTypo(typo: ETypo): SerializedStyles {
  const mappedTypo = typoMapper(typo)
  return getStyles(mappedTypo)
}

export function getRawTypo(typo: ETypo): CSSProperties {
  const mappedTypo = typoMapper(typo)
  return getRawStyles(mappedTypo)
}

export function getFontSize(fontScale: EFontScale): SerializedStyles {
  const styles = TYPO[fontScale]

  return css`
    font-size: ${styles.SIZE};
    line-height: ${styles.LINE_HEIGHT};
    font-weight: ${styles.WEIGHT};
  `
}
