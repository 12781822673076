import * as React from "react"
import Container from "../container"
import * as S from "./header.style"
import MenuIcon from "../menu-icon"

const Header: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = React.useState(true)

  const toggleHamburger = (): void => {
    setIsCollapsed(!isCollapsed)
  }

  const MenuItem = isCollapsed ? S.NavBarItem : S.HamburgerMenuItem

  return (
    <Container>
      <S.StyledHeader isCollapsed={isCollapsed}>
        <S.Title isCollapsed={isCollapsed}>uxhouse</S.Title>
        <MenuIcon {...{ isCollapsed, toggleHamburger }} />
        <S.Menu isCollapsed={isCollapsed} id="menu">
          <MenuItem to="/about-us">community</MenuItem>
          <MenuItem to="/">contact</MenuItem>
        </S.Menu>
      </S.StyledHeader>
    </Container>
  )
}

export default Header
