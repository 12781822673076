export const SPACE = {
  SPACE8: "8px",
  SPACE16: "16px",
  SPACE24: "24px",
  SPACE32: "32px",
  SPACE48: "48px",
  SPACE64: "64px",
  SPACE80: "80px",
  SPACE168: "168px",
}
