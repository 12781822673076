import styled from "@emotion/styled"
import { ETypo, getTypo, BREAKPOINT, EFontScale, getFontSize, SPACE } from "../../design"

export const StyledHeadline = styled.h1`
  ${getTypo(ETypo.MAJOR)};
  margin-bottom: ${SPACE.SPACE64};

  @media (max-width: ${BREAKPOINT.LARGE}) {
    ${getFontSize(EFontScale.F5)};
  }

  @media (max-width: ${BREAKPOINT.MEDIUM}) {
    ${getFontSize(EFontScale.F2)};
  }
`
