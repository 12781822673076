import styled from "@emotion/styled"
import { SPACE, BREAKPOINT } from "../../design"

export const StyledContainer = styled.div`
  max-width: ${BREAKPOINT.LARGE};
  margin: 0 auto;

  @media (max-width: calc(${BREAKPOINT.LARGE} + ${SPACE.SPACE32})) {
    margin: 0 ${SPACE.SPACE32};
  }

  @media (max-width: ${BREAKPOINT.SMALL}) {
    margin: 0 ${SPACE.SPACE16};
  }
`
