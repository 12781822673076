import * as React from "react"
import Hamburger from "../../assets/hamburger-icon.svg"
import Close from "../../assets/close-icon.svg"
import { StyledI } from "./menu-icon.style"

type MenuProps = {
  isCollapsed: boolean
  toggleHamburger: () => void
}

const MenuIcon = ({ isCollapsed, toggleHamburger }: MenuProps) => {
  return (
    <StyledI onClick={toggleHamburger} id="menu-icon">
      {isCollapsed ? <Hamburger title="Open the menu" /> : <Close title="Close the menu" onClick={toggleHamburger} />}
    </StyledI>
  )
}

export default MenuIcon
