export const COLOR = {
  GREY900: "#F2EEEF",
  GREY700: "#CBC4C6",
  GREY500: "#97898D",
  GREY300: "#5E5356",
  GREY100: "#231F20",

  YELLOW900: "#FFF4DB",
  YELLOW700: "#FFDE94",
  YELLOW500: "#FFC84C",
  YELLOW300: "#C78A00",
  YELLOW100: "#422E00",

  BLUE900: "#E4E9F8",
  BLUE700: "#ADBEEB",
  BLUE500: "#4881D5",
  BLUE300: "#355589",
  BLUE100: "#202C43",

  SUCCESS: "#4BD548",
  DANGER: "#E64F41",
}
